<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Role" :edit="edit" :save="save" :cancel="cancel" :remove="remove">
        <h1 class="title" data-test="sel-role-header">
          <breadcrumb location="/roles" text="Rôle"></breadcrumb>
          {{ role.name }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li :class="{'is-active': currentTab == 0}" @click="currentTab = 0">
                <a>Informations</a>
              </li>
              <li :class="{'is-active': currentTab == 1}" @click="currentTab = 1">
                <a>Menu</a>
              </li>
              <li :class="{'is-active': currentTab == 2}" @click="currentTab = 2">
                <a>Objets</a>
              </li>
              <li :class="{'is-active': currentTab == 3}" @click="currentTab = 3">
                <a>Options</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab == 0">
          <text-field label="Nom" v-model="role.name" required></text-field>
          <textarea-field label="Description" v-model="role.description"></textarea-field>
          <selector-field
            label="Visibilité"
            v-model="role.visibility"
            :options="$Api.Role.fetchVisibilityTypes"
          ></selector-field>
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab == 1">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">Menu</th>
                <th class="has-text-centered">Affichage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="editMode">
                <td></td>
                <td>
                  <select-all v-model="role.menus" :entities="menus" pKey="id"/>
                </td>
              </tr>
              <tr v-for="(menu, index) in menus" :key="'menu_idx_' + index">
                <td class="has-text-left">
                  {{ menu.parent ? menu.parent.title + ' / ' : '' }}{{ menu.title }}
                </td>
                <td>
                  <checkbox-field :value="menu.id" v-model="role.menus" :data-test="getMenuCy(menu)" :inline="false"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="panel-block is-block no-border objects" v-if="currentTab == 2">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">Objet</th>
                <th class="has-text-centered">Création</th>
                <th class="has-text-centered">Affichage</th>
                <th class="has-text-centered">Édition</th>
                <th class="has-text-centered">Suppression</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="editMode">
                <td></td>
                <td>
                  <select-all v-model="role.create" :entities="entities" pKey="code"/>
                </td>
                <td>
                  <select-all v-model="role.read" :entities="entities" pKey="code"></select-all>
                </td>
                <td>
                  <select-all v-model="role.update" :entities="entities" pKey="code"></select-all>
                </td>
                <td>
                  <select-all v-model="role.delete" :entities="entities" pKey="code"></select-all>
                </td>
              </tr>
              <tr v-for="(entity, index) in entities" :key="'entities_idx_' + index">
                <td class="has-text-left">{{ entity.name }}</td>
                <td>
                  <checkbox-field :value="entity.code" v-model="role.create" :inline="false"/>
                </td>
                <td>
                  <checkbox-field :value="entity.code" v-model="role.read" :inline="false"/>
                </td>
                <td>
                  <checkbox-field :value="entity.code" v-model="role.update" :inline="false"/>
                </td>
                <td>
                  <checkbox-field :value="entity.code" v-model="role.delete" :inline="false"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab == 3">
          <article class="message is-dark">
            <div class="message-body">
              Vous pouvez définir un ensemble de permissions supplémentaires sur ce rôle.
            </div>
          </article>

          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th class="has-text-left">Permissions</th>
                <th class="has-text-centered">Active</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="category in options">
                <tr :key="category.code">
                  <td class="has-text-left"><b>{{ category.name }}</b></td>
                  <td></td>
                </tr>
                <tr v-for="option in category.options" :key="category.code + option.code">
                  <td class="has-text-left">{{ option.name }}</td>
                  <td><checkbox-field :value="category.code + '.' + option.code" v-model="role.options" :inline="false"></checkbox-field></td>
                </tr>
              </template>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'role',
  props: ['id'],
  computed: {
    ...mapGetters({
      editMode: 'App/isEdition',
    }),
  },
  data() {
    return {
      currentTab: 0,
      role: {},
      menus: [],
      entities: [],
      options: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get('/menus').then((response) => {
        this.menus = response.data;
      });

      axios.get('/roles/options').then((response) => {
        this.options = response.data;
      });

      axios
        .get('/entities')
        .then((response) => {
          this.entities = response.data;
        })
        .then(() => {
          axios.get(`/roles/${this.id}`).then((response) => {
            this.$store.dispatch('App/setEditing', false);
            this.role = response.data;
            const visibilityTypes = this.$Api.Role.fetchVisibilityTypes();
            this.role.visibility = visibilityTypes[this.role.visibility - 1];
          });
        });
    },
    save() {
      const visibilityTypes = this.$Api.Role.fetchVisibilityTypes();
      const visibilityCode = visibilityTypes.findIndex(
        type => type === this.role.visibility,
      );
      const role = {
        ...this.role,
      };
      role.visibility = visibilityCode + 1;
      return axios.put(`/roles/${this.id}`, role).then((response) => {
        if (response.data.success) {
          this.fetchData();
        }
      });
    },
    edit() {
      this.$store.dispatch('App/setEditing', true);
    },
    cancel() {
      this.fetchData();
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/roles/${this.id}`).then((res) => {
            if (res.data.success) {
              this.$router.replace("/roles");
            }
          })
      );
    },
    getMenuCy(menu)
    {
      let cyTitle = menu.parent ? menu.parent.title.replace(' ', '-').toLowerCase() +'-' : '';
      return `sel-menu-${cyTitle}${menu.title.replace(" ", '-').toLowerCase()}`;
    }
  },
};
</script>

<style lang='scss' scoped>
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.table {
  th,
  td {
    text-align: center;
  }
}

.no-border {
  border: none;
  div {
    width: 100%;
    span {
      width: 30%;
      display: inline-block;
    }
  }
}
</style>
